import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/dashboard");
  },

  GetSold() {
    return Api().get("/mustsold");
  },
  GetNotification() {
    return Api().get("/history");
  },

  GetChart(year) {
    return Api().get("/chart?year=" + year);
  },


};
