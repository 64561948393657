<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div v-if="!loading_card">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Orders</span>
              <div class="text-900 font-medium text-xl">
                {{ card_data?.total_transaction }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"> + {{ card_data?.total_transaction_weekly }} </span>
          <span class="text-500"> since last week</span>
        </div>
        <div v-else>
          <Skeleton width="100%" height="5rem" />
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div v-if="!loading_card">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Customers</span>
              <div class="text-900 font-medium text-xl">
                {{ card_data?.total_user }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-inbox text-cyan-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">+{{ card_data?.total_user_weekly }} </span>
          <span class="text-500"> newly registered on this week</span>
        </div>
        <div v-else>
          <Skeleton width="100%" height="5rem" />
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div v-if="!loading_card">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Revenue</span>
              <div class="text-900 font-medium text-xl">
                {{ formatRupiah(card_data?.total_revenue, "Rp.") }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-map-marker text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">+{{ formatRupiah(card_data?.total_revenue_weekly, "Rp.") }} </span>
          <span class="text-500"> since last week</span>
        </div>
        <div v-else>
          <Skeleton width="100%" height="5rem" />
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div v-if="!loading_card">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Total Voucher</span>
              <div class="text-900 font-medium text-xl">
                {{ card_data?.total_voucher }}
              </div>
            </div>
            <!-- <div
            class="flex align-items-center justify-content-center bg-purple-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div> -->
          </div>
        </div>
        <div v-else>
          <Skeleton width="100%" height="5rem" />
        </div>
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <h5>Lastest User registered</h5>
        <DataTable :loading="is_loading_panel" :value="new_user_data" :rows="5" :paginator="true" responsiveLayout="scroll">
          <Column field="Email" header="Email" :sortable="true" style="width: 35%">
            <template #body="slotProps">
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true" style="width: 35%">
            <template #body="slotProps">
              {{ slotProps.data.is_active ? "Active" : "Not Active" }}
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Best Selling Products</h5>
          <div>
            <!-- <Button
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-plain p-button-rounded"
              @click="$refs.menu2.toggle($event)"
            ></Button>
            <Menu ref="menu2" :popup="true" :model="items"></Menu> -->
          </div>
        </div>
        <div v-if="!loading_sold_product">
          <ul class="list-none p-0 m-0">
            <li
              v-for="(data, i) in sold_product"
              :key="i"
              class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
            >
              <div>
                <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ data.name }}</span>
              </div>
              <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
                <!-- <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-teal-500 h-full" style="width: 40%"></div>
              </div> -->
                <span class="text-teal-500 ml-3 font-medium">{{ data.total_sold }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <Skeleton width="100%" height="5rem" />
        </div>
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Sales Overview</h5>
          <div>
            <Dropdown
              id="inventoryStatus"
              v-model="year"
              :options="[
                { label: 2022, value: 2022 },
                { label: 2023, value: 2023 },
                { label: 2024, value: 2024 },
                { label: 2025, value: 2025 },
              ]"
              @change="handleYear"
              optionLabel="label"
              placeholder="Select Year"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>
        </div>
        <div v-if="!loading_chart">
          <Chart type="line" :data="lineData" :options="lineOptions" />
        </div>
        <div v-else>
          <Skeleton width="100%" height="15rem" />
        </div>
      </div>
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-4">
          <h5>Notifications</h5>
          <!-- <div>
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-plain p-button-rounded"
              @click="$refs.menu1.toggle($event)"
            ></Button>
            <Menu ref="menu1" :popup="true" :model="items"></Menu>
          </div> -->
        </div>
        <div v-if="!loading_notification">
          <div v-for="(data, i) in notification" :key="i">
            <span class="block text-600 font-medium mb-3">{{ data.date }}</span>
            <ul :key="i" v-for="(dataset, i) in data.data" class="p-0 mx-0 mt-0 mb-4 list-none">
              <!-- paid -->
              <li v-if="dataset.type == 'paid'" class="flex align-items-center py-2 border-bottom-1 surface-border">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-dollar text-xl text-green-500"></i>
                </div>
                <span class="text-900 line-height-3">{{ dataset.msg }} </span>
              </li>
              <!-- cancel -->
              <li v-if="dataset.type == 'cancel'" class="flex align-items-center py-2 border-bottom-1 surface-border">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-times text-xl text-pink-500"></i>
                </div>
                <span class="text-900 line-height-3">{{ dataset.msg }} </span>
              </li>
              <!-- new order -->
              <li v-if="dataset.type == 'create'" class="flex align-items-center py-2">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-inbox text-xl text-orange-500"></i>
                </div>
                <span class="text-700 line-height-3">{{ dataset.msg }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <Skeleton width="100%" height="9rem" />
        </div>
      </div>
      <!-- <div
        class="px-4 py-5 shadow-2 flex flex-column md:flex-row md:align-items-center justify-content-between mb-3"
        style="
          border-radius: 1rem;
          background: linear-gradient(
              0deg,
              rgba(0, 123, 255, 0.5),
              rgba(0, 123, 255, 0.5)
            ),
            linear-gradient(92.54deg, #1c80cf 47.88%, #ffffff 100.01%);
        "
      >
        <div>
          <div class="text-blue-100 font-medium text-xl mt-2 mb-3">
            TAKE THE NEXT STEP
          </div>
          <div class="text-white font-medium text-5xl">Try PrimeBlocks</div>
        </div>
        <div class="mt-4 mr-auto md:mt-0 md:mr-0">
          <a
            href="https://www.primefaces.org/primeblocks-vue"
            class="p-button font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised"
          >
            Get Started
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import EventBus from "@/AppEventBus";
import DashboardApi from "../../api/Dashboard";
import ProductService from "../../service/ProductService";
import UserApi from "../../api/User";

export default {
  data() {
    return {
      products: null,
      card_data: {},
      loading_card: false,
      year: { label: 2022, value: 2022 },
      lineData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Okt", "Nov", "Des"],
        datasets: [
          {
            label: "Revenue(Jt)",
            data: [],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.1,
          },
          {
            label: "Sales",
            data: [],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.1,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
      lineOptions: null,
      is_loading_panel: false,
      new_user_data: [],
      sold_product: [],
      loading_sold_product: false,
      loading_notification: false,
      notification: [],
      loading_chart: false,
    };
  },
  productService: null,
  themeChangeListener: null,

  mounted() {
    // this.productService
    //   .getProductsSmall()
    //   .then((data) => (this.products = data));

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    } else {
      this.applyLightTheme();
    }
  },
  beforeUnmount() {
    EventBus.off("change-theme", this.themeChangeListener);
  },
  async created() {
    this.is_loading_panel = true;
    this.loading_card = true;
    this.loading_sold_product = true;
    this.loading_notification = true;
    this.loading_chart = true;

    await this.GetUser();
    await this.GetChart();
    await this.GetDashboard();
    await this.GetSold();
    await this.GetNotification();
    this.productService = new ProductService();
  },
  methods: {
    async handleYear() {
      await this.GetChart(this.year.value);
    },
    async GetUser() {
      this.is_loading_panel = true;
      try {
        let user_data = await UserApi.GetNew();
        this.new_user_data = user_data?.data?.data ? user_data?.data?.data : [];
        this.is_loading_panel = false;
      } catch (error) {
        this.is_loading_panel = false;
      }
    },
    async GetChart(year) {
      this.loading_chart = true;
      try {
        let chart = await DashboardApi.GetChart(year ? year : 2022);
        if (chart.data.success) {
          this.lineData.datasets[0].data = chart?.data?.data.revenue ? chart?.data?.data.revenue : [];
          this.lineData.datasets[1].data = chart?.data?.data.order ? chart?.data?.data.order : [];
        }

        this.loading_chart = false;
      } catch (error) {
        this.loading_chart = false;
      }
    },
    async GetDashboard() {
      this.loading_card = true;

      try {
        let res = await DashboardApi.Get();
        if (res.data.success) {
          this.card_data = res.data.data;
        }
        this.loading_card = false;
      } catch (error) {
        this.loading_card = false;
      }
    },
    async GetSold() {
      this.loading_sold_product = true;

      try {
        let res = await DashboardApi.GetSold();
        if (res.data.success) {
          this.sold_product = res.data.data;
        }
        this.loading_sold_product = false;
      } catch (error) {
        this.loading_sold_product = false;
      }
    },
    async GetNotification() {
      this.loading_notification = true;

      try {
        let res = await DashboardApi.GetNotification();
        if (res.data.success) {
          let unique_id = [...new Set(res.data.data.map((el) => el.date))];
          let dataset = unique_id.map((el) => {
            return {
              date: el,
              data: res.data.data.filter((elem) => elem.date == el),
            };
          });
          this.notification = dataset;
        }
        this.loading_notification = false;
      } catch (error) {
        this.loading_notification = false;
      }
    },
    formatRupiah(angka, prefix) {
      if (!angka) return;
      angka = angka.toString();
      var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    },

    // formatCurrency(value) {
    //   return value.toLocaleString("en-US", {
    //     style: "currency",
    //     currency: "USD",
    //   });
    // },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#ebedef",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
          y: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
        },
      };
    },
  },
};
</script>
